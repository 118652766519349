import request from '@/utils/request'
// import store from '@/store'


const api = {
  mapList(){
    return request.post(`/mobile/pubApi/getOutlets`)
  },
  videoList() {
    return request.post(`/mobile/pubApi/getTeachingVideo`)
  },
  doLogin(){
    return request.get(`'/mobile/api/weiXinLogin'`)
  },
  doRegister(params){
    return request.post(`/auth/api/weiXinRegister`,params)
  },
  getUserInfo(){
    return request.get(`/auth/api/getUserDate`)
  },
  getFalseUserInfo(){
    return request.get('/lease/needPermission/getFalseUserInfo')
  },

  getPhoneCode(code) {
    return request.post(`/auth/api/faSongYZM`,code)
  },
  getChcnavDefine(params) {
    return request.post(`/mobile/api/getRegistrationCode`,params)
  },
  getUserActiveCode(params){
    return request.post(`/mobile/api/getUserRegistrationCode`,params)
  },
  getUserPostMessage(params){
    return request.get('/mobile/api/selectRelease',params)
  },
  getInsertRelease(params){
    return request.post('/mobile/api/insertRelease',params)
  },
  getReleaseStatus(params){
    return request.post('/mobile/api/releaseStatus',params)
  },
  getSelect(){
    return request.get('/mobile/pubApi/selectAll')
  },
  getCode(params){
    return request.post('/mobile/api/GetQRcode',params)
  },
  getShares(params){
    return request.post('/mobile/api/getShares',params)
  }
}

export default api
