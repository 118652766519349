<!-- 注册页面 -->
<template>
  <div class="login-bg">
    <title-return title="注册" :url="'/my'"/>

    <van-form ref="loginForm" @submit="onSubmit" style="padding-top:40px">
      <div class="login-item">
        <van-field
          v-model="user.name"
          name="姓名"
          size="large"
          placeholder="姓名"
          :rules="userFormRules.name"
          maxlength="6"
        />
      </div>
      <div class="login-item">
        <van-field
          size="large"
          v-model="user.mobile"
          name="mobile"
          placeholder="请输入手机号"
          :rules="userFormRules.mobile"
          maxlength="11"
        />
      </div>
      <div class="login-item">
        <van-field size="large" v-model="user.code" maxlength="4" :rules="userFormRules.code" center clearable placeholder="获取验证码">
          <template #button>
            <!-- <van-button size="small" type="primary">发送验证码</van-button> -->
            <van-count-down
              v-if="isCountDownShow"
              :time="1000 * 120"
              format="ss s"
              @finish="isCountDownShow = false"
              class="time"
            />
            <van-button
              v-else
              class="send-sms-btn"
              native-type="button"
              size="small"
              type="primary"
              @click="onSendSms()"
            >发送验证码</van-button>
          </template>
        </van-field>
      </div>
      <div style="margin: 40px 24px">
        <van-button  block type="primary" native-type="submit">
          注册</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import api from '@/utils/api';
import titleReturn from '@/components/title-return.vue';
import {Dialog, Notify, Toast} from "vant";
import router from "@/router";
import TEMPURL from "../../utils/tempurl";
export default {
  data() {
    return {
      user: {
        name: "",//姓名
        mobile: '', //手机号
        code: '' //验证码

      },
      checked: false,//复选框
      show: false, //控制弹出层
      userFormRules: {
        name: [{
          required: true,
          message: '姓名不能为空'
        }, {
          pattern: /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/,
          message: '姓名格式错误'
        }],

        mobile: [{
          required: true,
          message: '手机号不能为空'
        }, {
          pattern: /^(((13[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[3-8]{1})|(18[0-9]{1})|(19[0-9]{1})|(14[5-7]{1}))+\d{8})$/,
          message: '手机号格式错误'
        }],
        code: [{
          required: true,
          message: '验证码不能为空'
        }, {
          pattern: /^\d{4}$/,
          message: '验证码格式错误'
        }]
      },
      isCountDownShow: false // 是否展示倒计时
    };
  },
  components: {
      titleReturn
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}'){
      Notify({ type: 'success', message: '系统繁忙，请再试一次' })
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('username');
      window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
    }
  },
  methods: {
    //控制弹出层
    showPopup() {
      this.show = true;
    },
    async onSubmit() {
      let params = {
        name: this.user.name.substring(0,4),
        username: this.user.mobile,
        code: this.user.code,
        password: "maplaysPWD",
        headpic: this.$route.query.headpic,
        openid: this.$route.query.openid,
        unionid: this.$route.query.unionid,
        region: this.$route.query.diqu,
      }
     let res = await api.doRegister(params);
      if (res.code === 0){
        Notify({ type: 'success', message: '注册成功' })
        Toast.loading({
          message: '登陆中...',
          forbidClick: true,
        });
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
      }else if (res.code === -2){
        Notify({ type: 'danger', message: '验证码错误！' })
      }else if (res.code === -3){
        Notify({ type: 'success', message: '注册成功' })
        Toast.loading({
          message: '登陆中...',
          forbidClick: true,
        });
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
      }else if (res.code === -4){
        Notify({ type: 'danger', message: '用户信息有误！' })
      }else if (res.code === 6){
        Notify({ type: 'danger', message: '绑定的老用户格式有误！' })
      }
    },
    async onSendSms () {
      // 1. 校验手机号
      try {
        await this.$refs.loginForm.validate('mobile')
      } catch (err) {
        Notify({ type: 'danger', message: '输入手机号有误' })
        return;
      }
      let data = await api.getPhoneCode({username : this.user.mobile});
      if (data.code === 0){
        Notify({ type: 'success', message: '已发送验证码!' })
      }else if (data.code === -1){
        Notify({ type: 'danger', message: '发送失败，请5分钟后再试！' });
      }
      // 2. 验证通过，显示倒计时
      this.isCountDownShow = true
    }

  },
};
</script>
<style lang="less" scoped>
   .login-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        overflow-y: auto;
        background-color: #fff;
   }
  .login-title {
    font-size: 24px;
    color: #333333;
    margin: 20px 20px;
  }

  .login-item {
    margin: 0 40px 15px;
    border-bottom: 1px solid #CECECE;
    .van-cell {
      padding: 10px 0;
    }
    .time {
      font-size: 14px;
      line-height: 30px;
      width: 78px;
      text-align: center;
      border: 1px solid #999999;
      color: #999999;
      border-radius: 3px;
    }
    .send-sms-btn {
        height: 64px;
    }

  }
  .van-field__control {
    font-size: 18px;
  }
  .van-button--primary {
      color: #fff;
      background-color: rgb(254, 138, 79);;
      border: 1px solid rgb(254, 138, 79);;
   }
</style>
