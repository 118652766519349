<template>
    <div>
        <van-nav-bar
        :title="title"
        left-arrow
        @click-left="onClickLeft"
        />
    </div>
</template>
<script>
export default {
    props: ['title','url'],
    methods: {
        onClickLeft () {
            this.$router.push({path : this.url})
        }
    }
}
</script>
<style  lang="less">
    .van-nav-bar .van-icon {
        color: #999;
        font-size: 24px;
    }
    .van-hairline--bottom::after {
        border-bottom-width: 0;
    }
</style>
